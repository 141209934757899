const DESKTOP_WIDTH_EDGE = 1024;
const MOBILE_DEVICE_TYPE = `mobile`;
const TABLET_DEVICE_TYPE = `tablet`;
const DESKTOP_DEVICE_TYPE = `desktop`;

export const getViewportWidth = () => window.innerWidth || document.documentElement.clientWidth;
export const getViewportHeight = () => window.innerHeight || document.documentElement.clientHeight;

export function elementInViewport(element, topModifier = 0) {
  const bottom = element.getBoundingClientRect().bottom;

  return bottom <= getViewportHeight() + topModifier && bottom > 0;
}

export function isVisible(element) {
  return elementInViewport(element, element.clientHeight);
}

export const isInDesktopRange = () => getViewportWidth() > DESKTOP_WIDTH_EDGE;

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return `tablet`;
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    return `mobile`;
  }
  return `desktop`;
};

export const isMobile = () => getDeviceType() === MOBILE_DEVICE_TYPE;
export const isTablet = () => getDeviceType() === TABLET_DEVICE_TYPE;
export const isDesktop = () => getDeviceType() === DESKTOP_DEVICE_TYPE;
