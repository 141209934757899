import createElement from '../../services/create-element';

export const initAdScripts = () => {
  const adScripts = window.Site.adScripts;

  adScripts.forEach(({ src, attributes, name }) => {
    const script = createElement(`script`, { src, ...attributes });

    script.onload = () => {
      if (name) {
        window[`${name}ScriptIsLoad`] = true;
        if (window[`${name}ScriptOnLoad`]) {
          window[`${name}ScriptOnLoad`]();
        }
      }
    };

    document.head.appendChild(script);
  });
};
