import throttle from '../services/throttle';

export const hideHeader = () => {
  window.document.body.classList.add(`header-collapsed`);
};

export const showHeader = () => {
  window.document.body.classList.remove(`header-collapsed`);
};

export const isHidden = () => window.document.body.classList.contains(`header-collapsed`);

export const hideHeaderOnScroll = ($header, minScroll = 250) => {
  let lastScroll = minScroll;

  window.addEventListener(
    `scroll`,
    throttle(() => {
      const currentScroll = window.pageYOffset;

      // skip minScroll distance from the top of the page
      if (currentScroll <= minScroll) {
        return;
      }

      if (lastScroll <= currentScroll) {
        hideHeader();
      } else {
        showHeader();
      }

      lastScroll = currentScroll;
    }, 16),
  );
};
