import addScript from '../services/script-loader';

export const initAYTrackerScript = () => {
  if (window.Site.abTests.aySplitPercentControl) {
    window.assertive = {
      entityId: `oRseRbuzixwNbA8qs`,
      analytics: {
        integrations: {
          webAnalytics: true,
        },
        logUnfilled: true,
        override: {
          adServerCurrency: `EUR`,
        },
      },
      hash: {
        generator: `server`,
        values: 5000,
        key: `control`,
      },
    };

    addScript(`https://oRseRbuzixwNbA8qs.ay.delivery/client-v2.js`);
  }
};

export const initAYManagerScript = () => {
  addScript(`https://oRseRbuzixwNbA8qs.ay.delivery/manager/oRseRbuzixwNbA8qs`);
};
