import { getGeolocation } from '../services/get-geolocation';
import addScript from '../services/script-loader.js';

const fbPixel = window.Site.fbPixel;

export const initFacebookPixel = async () => {
  if (fbPixel) {
    const geo = await getGeolocation();

    if (geo !== `US`) {
      addScript(fbPixel.src);
    }
  }
};
