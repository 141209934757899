const TEST_NAME = `aymABTest`; // cookie and param name
const DME_CONTROL = `dme`;
const AYM_CONTROL = `aym`;
const splitPercentControl = window.Site.abTests.aySplitPercentControl; // Yield Manager percent control

export const runAYSplitTest = () => {
  if (splitPercentControl && window.sessionStorage) {
    let selectedGroup = window.sessionStorage.getItem(TEST_NAME);

    if (!selectedGroup) {
      selectedGroup = Math.random() < splitPercentControl ? DME_CONTROL : AYM_CONTROL;
      window.sessionStorage.setItem(TEST_NAME, selectedGroup);
    }
  }
};

export const isAYMEnabled = () =>
  splitPercentControl &&
  window.sessionStorage &&
  window.sessionStorage.getItem(TEST_NAME) === AYM_CONTROL;
