const $mask = document.querySelector(`.js-mask`);
let scrollPosition;

export const showGlobalMask = () => {
  scrollPosition = window.scrollY;
  document.body.classList.add(`body-blurred`, `body-fixed`);
  document.body.style.top = `-${scrollPosition}px`;
  $mask?.classList.add(`c-mask--show`);
};

export const hideGlobalMask = () => {
  document.body.classList.remove(`body-blurred`, `body-fixed`);
  window.scrollTo(0, scrollPosition);
  $mask?.classList.remove(`c-mask--show`);
};

$mask?.addEventListener(`click`, hideGlobalMask);

export const onGlobalMaskClick = (callback) => {
  $mask?.addEventListener(`click`, callback);
};
