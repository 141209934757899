import createElement from '../../services/create-element';
import { GDPR, USP } from './constants';

export const createButton = (placeholder, className, type, text) => {
  const buttonCallbackMap = {
    [GDPR]: () => window.__tcfapi(`displayConsentUi`, 2, () => {}),
    [USP]: () => window.__uspapi(`displayUspUi`),
  };

  const button = createElement(`a`, {
    class: className,
  });

  button.addEventListener(`click`, buttonCallbackMap[type]);
  button.textContent = text ?? placeholder.textContent;
  placeholder.replaceWith(button);
};
