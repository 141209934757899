export const getGeolocation = () =>
  fetch(`https://cloudflare.com/cdn-cgi/trace`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Network response was not ok`);
      }

      return response.text();
    })
    .then((data) => {
      const location = data.match(/loc=([A-Za-z]{2})/);

      if (!location) {
        throw new Error(`Location not found`);
      }

      return location[1];
    })
    .catch((error) => {
      console.error(`${error}, set to default - 'DE'`);
      return `DE`;
    });

export const isProperGeolocation = (currentGeo, locationsArray) =>
  locationsArray.some((value) => value === currentGeo);
