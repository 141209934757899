export default () => {
  const selection = window.getSelection();
  const href = document.location.href;
  const copyright_link = ` Read more: <a href="${href}">${href}</a><br>`;

  const divElement = document.createElement('div');

  divElement.style.position = 'absolute';
  divElement.style.left = '-99999px';
  divElement.innerHTML = selection + copyright_link;
  document.body.appendChild(divElement);
  selection.selectAllChildren(divElement);

  setTimeout(() => {
    divElement.parentElement.removeChild(divElement);
  }, 0);
};
