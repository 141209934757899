import { GDPR, USP } from './constants';
import { createButton } from './create-button';

export const createCmpButton = (placeholder, className, buttonType, buttonText) => {
  if (window.__tcfapi !== undefined && placeholder !== null) {
    window.__tcfapi(`addEventListener`, 2, ({ gdprApplies }) => {
      if (gdprApplies && buttonType === GDPR) {
        createButton(placeholder, className, buttonType, buttonText);
      }
    });
    window.__uspapi(`uspPing`, 1, (obj, status) => {
      if (status && obj.mode.includes(USP) && buttonType === USP) {
        createButton(placeholder, className, buttonType);
      }
    });
  }
};
